<template>
    <div class='createIndex'>
        <CreateAndEdit :did="did" />
    </div>
</template>
  
<script>
import CreateAndEdit from './components/CreateAndEdit.vue'
export default {
    name: 'createdeviceAnnexIndex',
    components: {
        CreateAndEdit
    },
    props: {
        did: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>
<style lang='scss' scoped></style>
  